import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import Layout from 'components/LandingLayout';
import metaTranslations from '../../lib/utils';
import SEO from 'components/seo';
import theme from '../../themes';
import NavbarComponent from '../../components/Landing/Navbar/Navbar';
import NavbarFeature from '../../components/Landing/NavbarFeature/NavbarFeature';
import { windowGlobal } from '../../config';
import FeatureHero from '../../components/Landing/FeatureHero/FeatureHero';
import CatalogDesktopImg from '../../images/Landing/catalog_hero_dsktp.png';
import CatalogMobileImg from '../../images/Landing/catalog_hero_mobile.png';
import {
  Container,
  ForTeachersContainer,
  TitleContainer,
} from '../../components/Landing/FeatureListContainer/styles';
import Activities from '../../images/landing/catalog_1.svg';
import OwnActivities from '../../images/landing/catalog_2.svg';
import ListItem from '../../components/Landing/ListItem/ListItem';
import { WithTrans } from '../../language/withTrans';

const COLOR = 'rgb(145,63,163, 0.1)';
const FEATURE_VIEW = 'CatalogView';

const ForTeachersWrapper = styled(ForTeachersContainer)`
  width: 100%;

  .ListItemContainer {
    width: 100%;
  }

  ${({ theme }) => `
  ${theme.breakPoints.tablet} {

  .ListItemContainer {
    width: 40%;
  }
  }
  `}
`;

const ItemContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${({ theme }) => `
  ${theme.breakPoints.tablet} {
    flex-direction: row;
  }
  `}
`;

const itemsForTeachers = [{
  title: 'Activities',
  description: '+2,000 activities designed for your students ages (0-48 months)',
  image: Activities,
},
{
  title: 'Own Activities',
  description: 'Upload your own activities to share with your organization',
  image: OwnActivities,
}]

const Catalog = ({ lang }) => {
  windowGlobal.localStorage.setItem('LANDING_LOCALE', lang);

  return (
    <Layout>
      <SEO
        title={metaTranslations[lang].title}
        description={metaTranslations[lang].description}
        meta={[{ name: 'og:image', content: 'https://kinedu-media.s3.amazonaws.com/classrooms_by_kinedu_2.jpg' }]}
      />
      <ThemeProvider theme={theme}>
        <NavbarComponent lang={'es'} />
        <NavbarFeature view={FEATURE_VIEW} />
        <div>
          <FeatureHero
            title={'Browse and chose from +2000 activities developed by our experts In early childhood education'}
            imageDesktop={CatalogDesktopImg}
            imageMobile={CatalogMobileImg}
          />
        </div>

        <Container>
          <ForTeachersWrapper color={COLOR}>
            <TitleContainer>FOR TEACHERS</TitleContainer>
            <ItemContainer>
              {itemsForTeachers.map(item => (
                <ListItem
                  title={item.title}
                  description={item.description}
                  image={item.image}
                />
              ))}
            </ItemContainer>
          </ForTeachersWrapper>
        </Container>
      </ThemeProvider>
    </Layout>
  );
};

export default WithTrans()(Catalog);
